<template>
    <div class="result-board">
        <div class="result-board-text" v-if="$store.state.pk.loser === 'all'">
            Draw
        </div>
        <!-- ps: ===会比较类型， 而==会将不一样的类型都转化为字符串来比较 -->
        <div class="result-board-text"
            v-else-if="$store.state.pk.loser === 'A' && $store.state.pk.a_id === parseInt($store.state.user.id)">
            Lose
        </div>
        <div class="result-board-text"
            v-else-if="$store.state.pk.loser === 'B' && $store.state.pk.b_id == $store.state.user.id">
            Lose
        </div>
        <div class="result-board-text" v-else>
            Win
        </div>
        <div class="result-board-btn">
            <button @click="restart" type="button" class="btn btn-warning btn-lg">
                再来!
            </button>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();

        const restart = () => {
            store.commit("updateStatus", "matching");
            store.commit("updateLoser", "none");

            // ps: 并没有断开连接，所以再回到匹配界面时，需要清空对手信息等之前匹配成功更新的信息
            store.commit("updateOpponent", {
                selfColor: -1,
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            });
            store.commit("updateShowMatchBtn", true);
        }

        return {
            restart,
        }
    }
}
</script>

<style>
div.result-board {
    height: 30vh;
    width: 30vw;
    background-color: rgba(50, 50, 50, 0.5);
    position: absolute;
    /* 一共是100 */
    top: 30vh;
    left: 35vw;
}

div.result-board-text {
    text-align: center;
    color: aliceblue;
    font-size: 50px;
    font-weight: 600;
    font-style: italic;
    /* 斜体 */
    padding-top: 5vh;
}

div.result-board-btn {
    text-align: center;
    padding-top: 6vh;
}</style>