<template>
    <div class="container">
        <!-- 使用grids布局，将一行分为12份，左边占3份，右边占9份 -->
        <div class="row">

            <div class="col-3">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-body" style="text-align: center;">
                        <!-- ps: src标签中如果写的是表达式而不是字符串，需要在前面加上':' -->
                        <img :src="$store.state.user.photo" alt="" style="width: 100%;">
                        <div class="user-username">
                            <span>{{ $store.state.user.username }}</span>
                        </div>
                        <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-toggle="modal"
                            data-bs-target="#user-rename">改名</button>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="user-rename" tabindex="-1">
                <div class="modal-dialog modal-none">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5">修改用户名</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <!-- 写表单 -->
                            <form>
                                <div class="mb-3">
                                    <label class="form-label">新用户名</label>
                                    <input v-model="newname.newname" type="text" class="form-control"
                                        placeholder="请输入您所期望的新名称">
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <div class="error-message">{{ newname.error_message }}</div>
                            <button type="button" class="btn btn-primary" @click="rename()">确定</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-9">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-header">
                        <!-- ps: 给字体增加样式，加上span标签 -->
                        <span style="font-size: 130%;">我的Bot</span>

                        <button type="button" class="btn btn-primary float-end" style="float: right; margin-left: 3px;"
                            data-bs-toggle="modal" data-bs-target="#suggestion-btn">
                            提示
                        </button>
                        <!-- Modal -->
                        <div class="modal fade" id="suggestion-btn" tabindex="-1">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5">代码模板</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="mb-3">
                                            <VAceEditor v-model:value="info" @init="editorInit" lang="c_cpp"
                                                theme="textmate" style="height: 600px" />
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">知道了</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- ps: float-end: 置于最后， data-bs-target: 通过id打开对应模态框 -->
                        <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal"
                            data-bs-target="#add-bot-btn">
                            创建Bot
                        </button>

                        <!-- Modal -->
                        <div class="modal fade" id="add-bot-btn" tabindex="-1">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5">创建Bot</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <!-- 写表单 -->
                                        <form>
                                            <div class="mb-3">
                                                <label for="add-bot-title" class="form-label">名称</label>
                                                <input v-model="botadd.title" type="text" class="form-control"
                                                    id="add-bot-title" placeholder="请输入Bot名称">
                                            </div>
                                            <div class="mb-3">
                                                <label for="add-bot-description" class="form-label">简介</label>
                                                <textarea v-model="botadd.description" class="form-control"
                                                    id="add-bot-description" rows="3" placeholder="请输入Bot简介"></textarea>
                                            </div>
                                            <div class="mb-3">
                                                <label for="add-bot-code" class="form-label">代码</label>
                                                <!-- <textarea v-model="botadd.content" class="form-control" id="add-bot-code" rows="7" placeholder="请编写Bot代码"></textarea> -->
                                                <VAceEditor v-model:value="botadd.content" @init="editorInit" lang="c_cpp"
                                                    theme="textmate" style="height: 300px" />
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="error-message">{{ botadd.error_message }}</div>
                                        <button type="button" class="btn btn-primary" @click="add_bot">创建</button>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>创建时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- 需要使用 ':key' 指定一个关键字为每一行绑定一个不同的key -->
                                <tr v-for="bot in bots" :key="bot.id">
                                    <td>{{ bot.title }}</td>
                                    <td>{{ bot.createtime }}</td>
                                    <td>
                                        <button type="button" class="btn btn-secondary" style="margin: 2px;"
                                            data-bs-toggle="modal"
                                            :data-bs-target="'#update-bot-modal' + bot.id">修改</button>
                                        <button type="button" class="btn btn-danger" @click="remove_bot(bot)"
                                            style="margin: 2px;">删除</button>

                                        <!-- Modal: 模态框, ps: 注意每个修改按钮对应的模态框应该不同 -->
                                        <div class="modal fade" :id="'update-bot-modal' + bot.id" tabindex="-1">
                                            <div class="modal-dialog modal-xl">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5">修改Bot</h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <!-- 写表单 -->
                                                        <form>
                                                            <div class="mb-3">
                                                                <label for="add-bot-title" class="form-label">名称</label>
                                                                <input v-model="bot.title" type="text" class="form-control"
                                                                    id="add-bot-title" placeholder="请输入Bot名称">
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="add-bot-description"
                                                                    class="form-label">简介</label>
                                                                <textarea v-model="bot.description" class="form-control"
                                                                    id="add-bot-description" rows="3"
                                                                    placeholder="请输入Bot简介"></textarea>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="add-bot-code" class="form-label">代码</label>
                                                                <!-- <textarea v-model="bot.content" class="form-control" id="add-bot-code" rows="7" placeholder="请编写Bot代码"></textarea> -->
                                                                <VAceEditor v-model:value="bot.content" @init="editorInit"
                                                                    lang="c_cpp" theme="textmate" style="height: 300px" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div class="error-message">{{ bot.error_message }}</div>
                                                        <button type="button" class="btn btn-primary"
                                                            @click="update_bot(bot)">保存修改</button>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">取消</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <BottomInfo></BottomInfo>
</template>

<script>
import { ref, reactive } from 'vue'; // 一般情况下，绑定变量用ref，绑定对象用reactive
import $ from 'jquery';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';
import BottomInfo from '@/components/BottomInfo.vue';

export default {
    components: {
        VAceEditor,
        BottomInfo,
    },

    setup() {
        ace.config.set(
            "basePath",
            "https://cdn.jsdelivr.net/npm/ace-builds@" + require('ace-builds').version + "/src-noconflict/")

        const store = useStore();
        let bots = ref([]);
        let info = ref();

        // 绑定表单上传的信息
        const botadd = reactive({
            title: "",
            description: "",
            content: "",
            error_message: "",
        });

        const newname = reactive({
            newname: "",
            error_message: "",
        });

        const refresh_bots = () => {
            $.ajax({
                url: "https://tpxontheway.cn/api/user/bot/getlist/",
                type: "get",
                headers: { // 非公开页面需要授权
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            })
        }

        const get_info = () => {
            $.ajax({
                url: "https://tpxontheway.cn/api/user/bot/getinfo/",
                type: "get",
                headers: { // 非公开页面需要授权
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    info.value = resp;
                }
            })
        }

        get_info();
        refresh_bots();

        const add_bot = () => {
            botadd.error_message = "";
            $.ajax({
                url: "https://tpxontheway.cn/api/user/bot/add/",
                type: "post",
                data: {
                    title: botadd.title,  // ps: ref才需要加.value，botadd为记录表单信息的对象reactive
                    description: botadd.description,
                    content: botadd.content,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        // 清空填写内容，防止下次打开内容还在
                        botadd.title = "";
                        botadd.description = "";
                        botadd.content = "";

                        // 关闭表单
                        Modal.getInstance("#add-bot-btn").hide();

                        // 刷新列表
                        refresh_bots();
                    } else {
                        botadd.error_message = resp.error_message;
                    }
                }
            })
        }

        const update_bot = (bot) => {
            bot.error_message = "";
            $.ajax({
                url: "https://tpxontheway.cn/api/user/bot/update/",
                type: "post",
                data: {
                    bot_id: bot.id,
                    title: bot.title,
                    description: bot.description,
                    content: bot.content,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        // 关闭表单
                        Modal.getInstance('#update-bot-modal' + bot.id).hide();

                        // 刷新列表
                        refresh_bots();
                    } else {
                        bot.error_message = resp.error_message;
                    }
                }
            })
        }

        const remove_bot = (bot) => {
            $.ajax({
                url: "https://tpxontheway.cn/api/user/bot/remove/",
                type: "post",
                data: {
                    bot_id: bot.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        refresh_bots();
                    }
                }
            })
        }

        const rename = () => {
            newname.error_message = "";
            $.ajax({
                url: "https://tpxontheway.cn/api/user/account/rename/",
                type: "post", // 对数据库有修改，用post
                data: {
                    id: store.state.user.id,
                    newname: newname.newname,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        // 关闭表单
                        Modal.getInstance('#user-rename').hide();
                        store.commit("updateUserName", newname.newname);
                        newname.newname = "";
                        newname.error_message = "";
                    } else {
                        newname.error_message = resp.error_message;
                        newname.newname = "";
                    }
                }
            })
        }

        return {
            bots,
            botadd,
            add_bot,
            update_bot,
            remove_bot,
            info,
            newname,
            rename,
        }
    }
}
</script>

<style scoped>
div.error-message {
    color: red;
}

.user-username {
    padding: 2px;
    text-align: center;
    overflow: hidden;
    /* 隐藏过长部分 */
    text-overflow: ellipsis;
    /* 用省略号替换 */
    white-space: nowrap;
    /* 拒绝换行 */
    max-width: 100%;
}
</style>