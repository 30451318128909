<template>
    <div class="tag">
        <img src="../assets/备案图标.png" alt="" style="width: 15px;">
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50010802005756" rel="noreferrer" target="_blank"
            style="color:aliceblue">渝公网安备50010802005756</a>
        &nbsp;
        <div class="vertical-line"></div>
        &nbsp;
        <a href="http://beian.miit.gov.cn/" style="color:aliceblue">渝ICP备2024020821号-1</a>
    </div>
</template>

<script>
</script>
 
<style scoped>
div.tag {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;
}

.vertical-line {
    border-left: 2px solid;
    height: 20px;
}

a {
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
}
</style>