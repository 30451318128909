const AC_GAME_OBJECTS = [];

export class AcGameObject {
    constructor() {
        AC_GAME_OBJECTS.push(this); //创建一个push一个：后创建的会覆盖掉之前创建的。
        this.timedelta = 0; //这一帧相对于上一帧的时间间隔
        this.has_called_start = false;

    }

    start() {   //只执行一次

    }

    update() {  //每一帧执行一次，除了第一帧之外

    }

    on_destory() {  //删除之前执行

    }

    destroy() {
        this.on_destory();

        for(let i in AC_GAME_OBJECTS){
            const obj = AC_GAME_OBJECTS[i];
            if(obj == this){
                AC_GAME_OBJECTS.splice(i);
                break;
            }
        }
    }

}

let last_timestamp; //上一次执行的时刻
const step = timestamp => {
    for(let obj of AC_GAME_OBJECTS){    //for-each: in引用下标，of引用值
        if(!obj.has_called_start){
            obj.has_called_start = true;
            obj.start();
        }else{
            obj.timedelta = timestamp - last_timestamp;
            obj.update();
        }
    }

    last_timestamp = timestamp;
    requestAnimationFrame(step) //当前帧结束后继续调用下一帧
}

requestAnimationFrame(step)






