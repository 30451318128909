import { createRouter, createWebHistory } from 'vue-router'
import PkIndexView from '@/views/pk/PkIndexView'
import RecordIndexView from '@/views/record/RecordIndexView'
import RecordContentView from '@/views/record/RecordContentView'
import RanklistIndexView from '@/views/ranklist/RanklistIndexView'
import UserBotIndexView from '@/views/user/bot/UserBotIndexView'
import NotFound from '@/views/error/NotFound'
import UserAccountLoginView from '@/views/user/account/UserAccountLoginView'
import UserAccountRegisterView from '@/views/user/account/UserAccountRegisterView'
import UserAccountAcWingWebReceiveCodeView from '@/views/user/account/UserAccountAcWingWebReceiveCodeView'
import store from '@/store/index'  // 读入当前是否在登录状态


const routes = [
  {
    // 重定向-根目录
    path: "/",
    name: "home",
    redirect: "/pk/",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/pk/",
    name: "pk_index",
    component: PkIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record/",
    name: "record_index",
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record/:recordId/",
    name: "record_content",
    component: RecordContentView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/ranklist/",
    name: "ranklist_index",
    component: RanklistIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/bot/",
    name: "user_bot_index",
    component: UserBotIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/account/login/",
    name: "user_account_login",
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/register/",
    name: "user_account_register",
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/acwing/web/receive_code/",
    name: "user_account_acwing_web_receive_code",
    component: UserAccountAcWingWebReceiveCodeView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/qq/receive_code",
    name: "user_account_qq_receive_code",
    component: () => import('@/views/user/account/UserAccountQQReceiveCodeView'),
    meta: {
      requestAuth: false
    }
  },
  {
    path: "/404/",
    name: "404",
    component: NotFound,
    meta: {
      requestAuth: false,
    }
  },
  {
    // 重定向-错误地址
    path: "/:catchAll(.*)", //.*表示可以匹配任意字符
    name: "error",
    redirect: "/404/",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 每次router执行前，调用的函数
router.beforeEach((to, from, next) => {
  // 前端页面授权
  if (to.meta.requestAuth && !store.state.user.is_login) { //当前页面需要授权，且用户没有登陆，则跳转到登陆页面。
    if (from.name !== to.name && !store.state.user.firstlogin) { // 点击退出不显示请登录提示
      store.commit("updateShowPleaseLogin", true);
    }
    if (store.state.user.firstlogin) store.commit("updateFirstLogin", false);
    next({ name: "user_account_login" }); //重定向
  } else {
    next();
  }
})

export default router
