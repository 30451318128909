<template>
    <PlayGround v-if="$store.state.pk.status === 'playing'" />
    <MatchGround v-if="$store.state.pk.status === 'matching'" />
    <ResultBoard v-if="$store.state.pk.loser !== 'none'" />
    <BottomInfo />
</template>

<script>
import PlayGround from '@/components/PlayGround.vue';
import MatchGround from '@/components/MatchGround.vue';
import ResultBoard from '@/components/ResultBoard.vue';
import BottomInfo from '@/components/BottomInfo.vue';
import { onMounted, onUnmounted } from 'vue'; // onMounted: 当组件被挂载之后执行的函数 , onUnmounted: 组件销毁时
import { useStore } from 'vuex';

export default {
    components: {
        PlayGround,
        MatchGround,
        ResultBoard,
        BottomInfo,
    },

    setup() {
        const store = useStore();
        const socketUrl = `wss://tpxontheway.cn/websocket/${store.state.user.token}`;

        store.commit("updateIsRecord", false); // 不是录像

        let socket = null;
        onMounted(() => {
            store.commit("updateOpponent", {
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            });
            //当前组件被创建时，需要建立连接
            socket = new WebSocket(socketUrl); // WebSocket类，自带。 ps: 创建此socket时，后端也执行了onOpen()

            // 所创建的WebSocket有着与后端类似的api
            socket.onopen = () => {
                console.log("connected!");
                store.commit("updateSocket", socket);
            }

            socket.onmessage = msg => {
                const data = JSON.parse(msg.data);
                // 当接受到匹配成功的信息
                if (data.event === "start-matching") {
                    store.commit("updateOpponent", { // 加载对手信息
                        selfColor: data.selfColor,
                        username: data.opponent_username,
                        photo: data.opponent_photo,
                    });
                    store.commit("updateShowMatchBtn", false); // 禁用取消按钮
                    setTimeout(() => {
                        store.commit("updateStatus", "playing"); // 跳转到PlayGround
                    }, 2000);
                    store.commit("updateGame", data.game); // 加载游戏地图
                } else if (data.event === "move") {
                    // 取前端的两条蛇，通过set_direction控制对应的移动
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;
                    snake0.set_direction(data.a_direction);
                    snake1.set_direction(data.b_direction);
                } else if (data.event === "result") {
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;

                    if (data.loser === "all" || data.loser === "A") {
                        snake0.status = "die";
                    }
                    if (data.loser === "all" || data.loser === "B") {
                        snake1.status = "die";
                    }
                    store.commit("updateLoser", data.loser);
                }
            }
        });

        onUnmounted(() => {
            socket.close();
            store.commit("updateStatus", "matching"); // 重置状态，玩家退出当前页面视作放弃
            store.commit("updateLoser", "none");
        });
    }

}
</script>

<style scoped></style>
