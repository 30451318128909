<template>
    <div class="matchground">
        <!-- grids -->
        <div class="row">
            <!-- 头像 -->
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.user.photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.user.username }}
                </div>
            </div>
            <div class="col-4 center-content">
                <div>
                    <div class="rule">
                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#rule-btn"
                            :disabled="!$store.state.pk.show_match_btn">操作规则</button>
                    </div>
                    <!-- Modal -->
                    <div class="modal" id="rule-btn" tabindex="-1">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">操作规则</h5>
                                </div>
                                <div class="modal-body">
                                    <p>这是一款联机版的对战贪吃蛇小游戏~</p>
                                    <p>玩家可以选择派出自己编写的Bot进行对战，或者亲自出马。若选择亲自出马，请注意：</p>
                                    <p>1. 游戏开始时，有黄色圆圈标注的那条蛇即为玩家需要操控的蛇；</p>
                                    <p>2. 通过方向键或WSAD(上下左右)控制蛇的下一步移动方向；</p>
                                    <p>3. 如果系统检测到玩家5秒未进行操作，则游戏失败。对战双方均完成操作后，进入下一步。</p>
                                    <p>PS: 若匹配时间超过10s，系统会为您安排一个人机（罗伯特）进行对局且人机对局不计算排名分。另外，若使用Bot进行对局，键盘操作将被禁用。</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">知道了</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="user-select-bot">
                        <select v-model="select_bot" class="form-select" aria-label="Default select example">
                            <option selected value="-1">亲自出马</option>
                            <option v-for="bot in bots" :key="bot.id" :value="bot.id">
                                {{ bot.title }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.pk.opponent_photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.pk.opponent_username }}
                </div>
            </div>
            <!-- 匹配按钮 -->
            <div class="col-12" style="text-align: center; padding-top: 15vh;">
                <button @click="click_match_btn" type="button" class="btn btn-warning btn-lg"
                    :disabled="!$store.state.pk.show_match_btn">{{ match_btn_info }}</button>
            </div>
            <span style="color: rgba(255, 255, 255, 0); user-select: none;">
                --------------------------------------------------------------------
            </span>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import $ from 'jquery';
import { onUnmounted } from 'vue';

export default {
    setup() {
        const store = useStore();
        let match_btn_info = ref("开始匹配");
        let bots = ref([]);
        let select_bot = ref(-1); // 双向数据绑定

        const click_match_btn = () => {
            if (match_btn_info.value === "开始匹配") {
                match_btn_info.value = "取消";
                // 使用JSON进行前后端通信；在JSON中添加一个event域用于区分匹配和取消。
                store.state.pk.socket.send(JSON.stringify({
                    event: "start-matching",
                    bot_id: select_bot.value,
                }));
            } else {
                match_btn_info.value = "开始匹配";
                store.state.pk.socket.send(JSON.stringify({
                    event: "stop-matching",
                }));
            }
        };

        // 获取bot列表
        const refresh_bots = () => {
            $.ajax({
                url: "https://tpxontheway.cn/api/user/bot/getlist/",
                type: "get",
                headers: { // 非公开页面需要授权
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            })
        }

        refresh_bots();

        onUnmounted(() => {
            // 当用户处于正在匹配，并在未取消匹配的情况下离开pk页面，直接通知后端将其移除匹配池
            if (match_btn_info.value === "取消") {
                store.state.pk.socket.send(JSON.stringify({
                    event: "stop-matching",
                }));
            }
        });

        return {
            match_btn_info,
            click_match_btn,
            bots,
            select_bot,
        }

    }

}

</script>

<style scoped>
div.matchground {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    background: rgba(50, 50, 50, 0.5);
    margin: 40px auto;
}

div.user-photo {
    text-align: center;
    padding-top: 10vh;
}

div.user-photo>img {
    /* 圆形图片 */
    border-radius: 50%;
    width: 12vh;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.user-username {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    padding-top: 2vh;
    color: white;
}

div.user-select-bot {
    padding-top: 0.5vh;
}

div.user-select-bot>select {
    margin: 0 auto;
    /* 居中 */
}

div.rule {
    margin: 0 auto;
    color: aliceblue;
    text-align: center;
    padding-top: 10vh;
}

.user-username {
    padding: 2px;
    text-align: center;
    overflow: hidden;
    /* 隐藏过长部分 */
    text-overflow: ellipsis;
    /* 用省略号替换 */
    white-space: nowrap;
    /* 拒绝换行 */
    max-width: 100%;
}
</style>