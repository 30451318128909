<template>
  <div class="playground">
      <GameMap />
  </div>
  <div style="text-align: center;">
      <div class="keyboard" >
          <div class="row">
              <div class="key up" @click="click_keyboard(0)">↑</div>
          </div>
          <div class="row">
              <div class="key left" @click="click_keyboard(3)">←</div>
              <div class="key down" @click="click_keyboard(2)">↓</div>
              <div class="key right" @click="click_keyboard(1)">→</div>
          </div>
        </div>
  </div>
</template>

<script>
import GameMap from '@/components/GameMap.vue';
import { useStore } from 'vuex';

export default {
  components: {
      GameMap,
  },
  setup() {
      const store = useStore();

      const click_keyboard = d => {
          store.state.pk.socket.send(JSON.stringify({
              event: "move",
              direction: d,
          }));
      }

      return {
          click_keyboard,
      }
  }
}

</script>

<style scoped>
div.playground {
  width: 55vw;
  height: 40vw;
  /* background: lightblue; */
  margin: 20px auto;
}
.keyboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.key {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  margin: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  cursor: pointer;
  user-select: none;
}

.key:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
  transition: 100ms;
}

.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.up {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.down {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

</style>