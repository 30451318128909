<template>
    <ContentField>
        <div class="row justify-content-md-center">
            <div class="col-md-auto">
                <form @submit.prevent="register">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="mb-3">
                        <label for="confirmedPassword" class="form-label">确认密码</label>
                        <input v-model="confirmedPassword" type="password" class="form-control" id="confirmedPassword"
                            placeholder="请再次输入密码">
                    </div>
                    <span style="color: rgba(255, 255, 255, 0); user-select: none;">
                        -----------------------------------------------
                    </span>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">提交</button>
                </form>
            </div>
        </div>
    </ContentField>
    <BottomInfo></BottomInfo>
</template>

<script>
import ContentField from '@/components/ContentField.vue' //例如ContentField不加大括号是因为：ContentField中export后面加了default
import BottomInfo from '@/components/BottomInfo.vue';
import { ref } from 'vue'
// import { useStore } from 'vuex'
import router from '@/router/index'
import $ from 'jquery';


export default {
    components: {
        ContentField,
        BottomInfo
    },
    setup() {
        // const store = useStore();
        let username = ref('');
        let password = ref('');
        let confirmedPassword = ref('');
        let error_message = ref('');

        const register = () => {    // 向后端传入（post）数据
            $.ajax({ //本质是一个字典
                url: "https://tpxontheway.cn/api/user/account/register/",
                type: "post",
                // headers: {  // 页面不需要授权，因为其在后端设置为了公开，和login一样
                //     Authorization: "Bearer " + store.state.user.token,
                // },
                data: {
                    username: username.value,
                    password: password.value,
                    confirmedPassword: confirmedPassword.value,
                },
                // "success": function(resp) { //第二种写法
                success(resp) {
                    if (resp.error_message === "success") {
                        router.push({ name: "user_account_login" });
                    } else {
                        error_message.value = resp.error_message;
                    }
                },
            });
        }

        return {
            username,
            password,
            confirmedPassword,
            error_message,
            register
        }
    }
}
</script>

<style scoped>
button {
    width: 100%;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.error-message {
    color: red;
}
</style>