<template>
  <NavBar />
  <router-view />
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
import { useStore } from 'vuex'
// import router from './router'
// import $ from 'jquery'

export default {
  components: {
    NavBar
  },

  setup() {
    // router.push({ name: "user_account_login" });
    const store = useStore();
    store.commit("updateFirstLogin", true);
  }
}
</script>

<style>
body {
  background-image: url("@/assets/images/background.webp");
  background-size: cover;
}
</style>
