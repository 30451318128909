<template>
    <div ref="parent" class="gamemap">
        <canvas ref="canvas" tabindex="0"></canvas>
    </div>
</template>

<script>
import { GameMap } from '@/assets/scripts/GameMap';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex'; // 传后端生成的地图

export default {
    setup() {
        const store = useStore();
        let parent = ref(null);
        let canvas = ref(null);

        // 当组件挂载完后需要执行的操作
        onMounted(() => {
            // new GameMap(canvas.value.getContext('2d'), parent.value, store)
            store.commit("updateGameObject", new GameMap(canvas.value.getContext('2d'), parent.value, store)) // 存下游戏对象
        })

        return {
            parent,
            canvas,
        }
    }
}
</script>

<style scoped>
div.gamemap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-content: center;
    /* 竖直居中 */
}

div.circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
</style>