// 用户存储与pk相关的全局变量

// import $ from 'jquery'

export default ({
    state: {
        status: "matching", // 记录当前状态。有两种："matching"表示匹配界面，"playing"表示对战界面
        socket: null, // 记录与后端的连接
        selfColor: -1, // 记录自己是那条蛇：snake_selfId
        // 记录对手信息
        opponent_username: "",
        opponent_photo: "",
        show_match_btn: null,
        gamemap: null,
        a_id: 0,
        a_sx: 0,
        a_sy: 0,
        b_id: 0,
        b_sx: 0,
        b_sy: 0,
        gameObject: null,
        loser: "none", // none、all、A、B
    },
    getters: {
    },
    mutations: {    //同步操作
        updateSocket(state, socket) {
            state.socket = socket;
            state.show_match_btn = true;
        },
        updateOpponent(state, data) {
            state.selfColor = data.selfColor;
            state.opponent_username = data.username;
            state.opponent_photo = data.photo;
        },
        updateStatus(state, status) {
            state.status = status;
        },
        updateShowMatchBtn(state, flag) {
            state.show_match_btn = flag;
        },
        updateGame(state, game) {
            state.gamemap = game.map;
            state.a_id = game.a_id;
            state.a_sx = game.a_sx;
            state.a_sy = game.a_sy;
            state.b_id = game.b_id;
            state.b_sx = game.b_sx;
            state.b_sy = game.b_sy;
        },
        updateGameObject(state, gameObject) {
            state.gameObject = gameObject;
        },
        updateLoser(state, loser) {
            state.loser = loser;
        }
    },
    actions: {  //异步操作
    },
    modules: {
    }
  })
  