<template>
    <PlayGround />
    <BottomInfo />
</template>

<script>
import PlayGround from '@/components/PlayGround.vue';
import BottomInfo from '@/components/BottomInfo.vue';

export default {
    components: {
        PlayGround,
        BottomInfo,
    },

}
</script>

<style scoped></style>
