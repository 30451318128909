<template>
    <span class="welcome">欢迎来到蛇蛇大作战！快来打造你的最强AI吧~</span>
    <ContentField v-if="!$store.state.user.pulling_info">
        <div class="row justify-content-md-center">
            <div class="col-md-auto">
                <div class="alert alert-warning alert-dismissible fade show" role="alert"
                    v-if="$store.state.user.show_please_login">
                    <button @click="clickAlertBtn()" class="alert-btn" data-bs-dismiss="alert" aria-label="Close">
                        请先登录~
                    </button>
                </div>
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div>
                        <div class="error-message">{{ error_message }}</div>
                        <button type="submit" class="btn btn-primary">登录</button>
                    </div>
                    <div style="margin-top: 3px;">
                        <span style="font-size: 10px; color:lightslategray">还没有账号？去注册一个吧：</span><span class="goRegister"
                            @click="goToRegister()">点击注册</span>
                    </div>
                </form>
                <span style="color: rgba(255, 255, 255, 0); user-select: none;">
                    -----------------------------------------------
                </span>
            </div>
            <div style="text-align: center;">
                <span style="color:lightslategray">—————— 其他方式登录 ——————</span>
                <br>
                <img src="../../../assets/02_qq_logo.png" alt="" style="cursor: pointer;" @click="qq_login()">
            </div>
            <!-- <div style="text-align: center; margin-top: 20px; cursor: pointer;" @click="acwing_login()">
                <img width="30" src="https://cdn.acwing.com/media/article/image/2022/09/06/1_32f001fd2d-acwing_logo.png"
                    alt="">
                <br>
                AcWing一键登录
            </div> -->
        </div>
    </ContentField>
    <BottomInfo />
    <!-- <div class="tag">
        <a href="http://beian.miit.gov.cn/" style="color:aliceblue">渝ICP备2024020821号-1</a>
    </div> -->
</template>

<script>
import ContentField from '@/components/ContentField.vue'
import BottomInfo from '@/components/BottomInfo.vue'
import { useStore } from 'vuex'
import { ref } from 'vue' //所有变量都需要用ref
import router from '@/router/index'
import $ from 'jquery'

export default {
    components: {
        ContentField,
        BottomInfo
    },
    setup() {
        const store = useStore();
        let username = ref(''); //v-model
        let password = ref('');
        let error_message = ref('');

        const jwt_token = localStorage.getItem("jwt_token");
        if (jwt_token) {
            store.commit("updateToken", jwt_token);
            store.dispatch("getinfo", { //dispatch使用store中actions的方法
                success() {
                    router.push({ name: "home" });
                    store.commit("updatePullingInfo", false);   //commit使用store中mutations的方法，拉取结束
                },
                error() {
                    store.commit("updatePullingInfo", false);
                }
            })
        } else {
            store.commit("updatePullingInfo", false);
        }

        const login = () => { // 绑定事件@submit.prevent="login"
            error_message.value = "";
            // 先登陆，再获得当前用户信息
            store.dispatch("login", {
                username: username.value, //ref取值是.value
                password: password.value,
                success() {
                    store.dispatch("getinfo", {
                        success() {
                            router.push({ name: 'home' });
                            // console.log(store.state.user)
                        }
                    });
                },
                error() {
                    error_message.value = "用户名或密码错误";
                }
            });
        }

        // const acwing_login = () => {
        //     $.ajax({
        //         url: "https://tpxontheway.cn/api/user/account/acwing/web/apply_code/",
        //         type: "GET",
        //         success: resp => {
        //             if (resp.result === "success") {
        //                 // 成功生成所需参数后，重定向到acwing端指定web端请求授权url，随后acwing端回调redirect_uri通信前端的/user/account/acwing/web/receive_code/页面
        //                 window.location.replace(resp.apply_code_url);
        //             }
        //         }
        //     })
        // }

        const qq_login = () => {
            $.ajax({
                url: "https://tpxontheway.cn/api/user/account/qq/apply_code/",
                type: "GET",
                success: resp => {
                    if (resp.result === "success") {
                        // 成功生成所需参数后，将用户跳转到qq端指定的页面确认qq授权登陆，若确认，随后qq端回调redirect_uri通信前端的/user/account/acwing/web/receive_code/页面
                        window.location.replace(resp.apply_code_url);
                    }
                }
            })
        }

        const goToRegister = () => {
            router.push({ name: 'user_account_register' });
        }

        const clickAlertBtn = () => {
            store.commit("updateShowPleaseLogin", false);
        }

        return {
            username,
            password,
            error_message,
            login,
            // acwing_login,
            qq_login,
            goToRegister,
            clickAlertBtn,
        }
    },
}
</script>

<style scoped>
.welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: aliceblue;
    font-weight: 600;
    font-size: 18px;
    font-style: italic;
    user-select: none;
}

.welcome:hover {
    color: aquamarine;
    transform: scale(1.5);
    transition: 100ms;
}

button {
    width: 100%;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.error-message {
    color: red;
}

.goRegister {
    font-size: 12px;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    user-select: none;
}

.alert {
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-btn {
    background-color: #FFF3CD;
    border: #FFF3CD;
}

div.tag {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>