<template>
    <div></div>
</template>

<script>
import router from '@/router';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import $ from 'jquery'

export default {
    setup() {
        // 接受acwing端传入的code和state
        const myRoute = useRoute();
        const store = useStore();

        // 重定向到该页面时，表示code已经获得，即向后端传入code和state完成后续receive_code逻辑。
        $.ajax({
            url: "https://tpxontheway.cn/api/user/account/acwing/web/receive_code/",
            type: "GET",
            data: {
                code: myRoute.query.code,
                state: myRoute.query.state,
            },
            success: resp => {
                if (resp.result === "success") {
                    localStorage.setItem("jwt_token", resp.jwt_token);  // 实现登陆的持久化，将token存入浏览器的本地内存中
                    store.commit("updateToken", resp.jwt_token);
                    router.push({ name: "home" }); // 重定向到首页
                    store.commit("updatePullingInfo", false);
                    console.log(resp)
                } else {
                    router.push({ name: "user_account_login" }); // 失败则返回登陆页面
                    console.log(resp)
                }
            }
        })
    }
}

</script>

<style scoped></style>