import { AcGameObject } from "./AcGameObject";  //每一帧都要重新渲染地图，除了export default其他的引用都需要加{}
import { Snake } from "./Snake";
import { Wall } from "./Wall";

export class GameMap extends AcGameObject {
    constructor(ctx, parent, store) {
        super();

        this.ctx = ctx;
        this.parent = parent;
        this.store = store;
        this.L = 0; //边长，绝对距离

        this.rows = 13;
        this.cols = 14;

        this.inner_walls_count = 20;
        this.walls = [];

        this.snakes = [
            new Snake({id: 0, color: "#4876EC", r: this.rows - 2, c: 1, selfColor: store.state.pk.selfColor}, this),
            new Snake({id: 1, color: "#F94848", r: 1, c: this.cols - 2, selfColor: store.state.pk.selfColor}, this),
        ];
    }

    create_walls() {   
        const g = this.store.state.pk.gamemap;

        for(let r = 0; r < this.rows; r++){
            for(let c = 0; c < this.cols; c++){
                if(g[r][c] == 1) this.walls.push(new Wall(r, c, this));
            }
        }
    }

    add_listening_events() {
        if (this.store.state.record.is_record) { 
            // 当前为播放录像
            const a_steps = this.store.state.record.a_steps;
            const b_steps = this.store.state.record.b_steps;
            const loser = this.store.state.record.record_loser;
            const [snake0, snake1] = this.snakes;

            let k = 0, len = a_steps.length;
            const interval_id = setInterval(() => {
                if (k >= len - 1) { // 到最后一步，不进行移动，直接显示结果并退出函数
                    if(loser === "all" || loser === "A") {
                        snake0.status = "die";
                    }
                    if(loser === "all" || loser === "B") {
                        snake1.status = "die";
                    }
                    clearInterval(interval_id);
                } else {
                    snake0.set_direction(parseInt(a_steps[k]));
                    snake1.set_direction(parseInt(b_steps[k]));
                    k += 1;
                }
            }, 234);

        } else {
            this.ctx.canvas.focus();

            this.ctx.canvas.addEventListener("keydown", e => {
                let d = -1;
                if(e.key === 'w') d = 0;
                else if(e.key === 'd') d = 1;
                else if(e.key === 's') d = 2;
                else if(e.key === 'a') d = 3;
                else if(e.key === 'ArrowUp') d = 0;
                else if(e.key === 'ArrowRight') d = 1;
                else if(e.key === 'ArrowDown') d = 2;
                else if(e.key === 'ArrowLeft') d = 3;

                // 如果操作合法，则向后端发送对应移动方向
                // 另外，如果玩家选择的是bot出战，后端会把传过去的键盘输入信息屏蔽掉
                if (d >= 0) {
                    this.store.state.pk.socket.send(JSON.stringify({
                        event: "move",
                        direction: d,
                    }));
                }
            });
        }
    }

    start() {
        this.create_walls();

        this.add_listening_events(); // 监听用户输入 或 播放录像
    }

    update_size() {
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;
    }

    check_ready() {
        // 判断两条蛇是否都准备好了下一回合：都处于静止状态且都获得了下一条指令。
        for(const snake of this.snakes) {
            if(snake.status !== "idle") return false;
            if(snake.direction === -1) return false; // 注意js语法
        }
        return true;
    }

    next_step() { //让两条蛇进入下一回合
        for(const snake of this.snakes) {
            snake.next_step();
        }
    }

    update() {  //每一帧执行一次
        this.update_size();
        if(this.check_ready()) {
            this.next_step();
        }
        this.render(); 
    }

    render() {
        // 绘制背景
        const color_even = "#AAD751", color_odd = "#A2D149";
        for(let r = 0; r < this.rows; r++) {
            for(let c = 0; c < this.cols; c++) {
                if((r+c) % 2 == 0){
                    this.ctx.fillStyle = color_even;
                }else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
            }
        }
    }

}
